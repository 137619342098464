import { AsyncPaginate } from 'react-select-async-paginate';
import { useEventsApi } from '../../../../lib/api/events';

const AsyncEventsPaginateComponent = ({ events, onChangeEvents, additionReqOpts, valueProp }) => {
  const { findAndCountEntries } = useEventsApi();

  const eventSelectOptions = (i) => {
    const value = i[valueProp || 'url'];
    const label = `${i.base_id?.name || ''}. ${i?.name || ''}`;
    const dynamicProp = { [valueProp || 'url']: i[valueProp || 'url'] };
    const base_id = { name: i.base_id?.name };
    return ({ value, label, name: i?.name, base_id, ...dynamicProp });
  }

  const onLoadEvents = async (query, opts, { offset: prevOffset, limit: prevLimit, query: prevQuery }) => {
    const reqOpts = {"_limit": prevLimit, "_start": prevOffset, "base_id_null": false, "base_id.is_archived_ne": true, ...additionReqOpts};
    if (query) {
      reqOpts.name_contains = query;
    }

    return findAndCountEntries(reqOpts).then(res => {
      const offset = query === prevQuery ? prevOffset + 10 : 0;
      const hasMore = (offset + prevLimit) <= res.total;

      return {
        options: res.data?.map(eventSelectOptions),
        hasMore,
        additional: {
          offset,
          query,
          limit: prevLimit,
        }
      };
    }).catch(() => {
      return {
        options: [],
        hasMore: false,
      }
    });
  }

  return (
    <AsyncPaginate
      value={events?.map(eventSelectOptions) || []}
      loadOptions={onLoadEvents}
      isMulti
      closeMenuOnSelect={false}
      onChange={onChangeEvents}
      debounceTimeout={1000}
      additional={{ offset: 0, limit: 10, query: "" }}
    />
  )
}

export default AsyncEventsPaginateComponent;